




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































   .el-aside {
       height: calc(100vh - 100px);// vh就是当前屏幕可见高度的1%   只用设置左侧 aside 高度
   }
   .oneMenu{

       text-align:left;
       font-size:11px;
       color:white;

   }
   .oneMenu:hover{
       cursor: pointer;
       color:#a322d6;
   }
   .twoMenu{
       display:flex;
       flex-direction:column;
       line-height:40px;
       background:white;
       padding-left:35px;

   }
   .twoMenu:hover{
       cursor: pointer;
       color:#409EFF;
   }
   .top{
       padding: 10px;
       background: rgba(0, 153, 229);
       color: #fff;
       text-align: center;
       font-size:10px;
       border-radius: 2px;
   }  
   .el-header  {
       background-color: #fff;
       color: #409EFF;
       text-align: center;
       line-height: 60px;
       width:100%;
   }
 .el-main {
   background-color: #fff;
   color: rgb(207, 206, 206);
   text-align: center;
   height:100%;
 }
.pub_dialog {
       display: flex;
       justify-content: center;
       align-items: Center;
       overflow: hidden;
       .el-dialog {
           margin: 1px auto !important;
           height: 90%;
           overflow: hidden;
           .el-dialog__body {
               position: absolute;
               left: 0;
               top: 54px;
               bottom: 0;
               right: 0;
               padding: 0;
               z-index: 1;
               overflow: hidden;
               overflow-y: auto;
           }
       }
   }
   .item-width  {
       width: 32%;
       color:#409EFF;
       min-width:200px;
   }
   .active { 
       color:#D200D2; 
   } 
   .wraper{
       width:1250px;
       margin:1px auto;
   }
